/* components/image_content.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01e,22aug24,mno  custom Markdown component.
01d,22aug23,mno  added top-margin for title/subtitle.
01c,30aug22,mno  named exports.
01b,20jul22,mno  added borderRadius attr; container attrs.
01a,08oct20,mno  created.
*/
import React from 'react';
import classNames from 'classnames';
import { includes, any } from 'ramda';
import isPresent from '../lib/is_present';
import CdnImg from './cdn_img';
import Markdown from './markdown'; 

const SHAPES = [
  /logo/,
  /1by[123]/, 
  /2by[13]/, 
  /3by[1245]/, 
  /4by[35]/, 
  /16by9/, 
  /9by16/
];

const getShapeCx = (shape) => {
  if (includes(shape, ['circle', 'round'])) return 'is-1by1';
  if (any(r => r.test(shape), SHAPES)) return `is-${shape}`;
  return '';
}

const getRoundedCx = (shape) => {
  if (shape === 'circle' || shape === 'round') return 'is-rounded';
  return '';
}

const getAlignCx = (align) => {
  switch(align) {
    case 'center':  return 'has-text-centered';
    case 'left':    return 'has-text-left';
    case 'right':   return 'has-text-right';
    case 'justify': return 'has-text-justified';
    default:        return '';
  }
}

const getSizeCx = (size) => {
  if (isPresent(size)) return `is-${size}`;
  return '';
}

const ImageContent = (props) => {
  const { 
    containerSize, containerShape, containerAlign, /* containerSpacing, */
    shape, size, url, alt, caption, title, subtitle, description, 
    className, align, isListItem, borderRadius, pageLink 
  } = props;

  const containerCx = classNames(
    className, 
    'image-content',
    getSizeCx(containerSize), 
    getShapeCx(containerShape),
    getAlignCx(containerAlign || align),
    {
      'is-list-item':       isListItem,
    }
  );

  const imageContent = (
    <figure 
      className={classNames('image', getShapeCx(shape), getAlignCx(align))}
      style={{borderRadius: borderRadius ? `${borderRadius}px` : 0}}
    >
      <CdnImg size={size} src={url} alt={alt} className={getRoundedCx(shape)}/>
    </figure>
  );

  return (
    <div className={containerCx}>
      <div className={classNames('image-block', getSizeCx(size))}>
        {
          isPresent(pageLink) 
            ? <a className="figure-link" href={pageLink}>{imageContent}</a>
            : imageContent
        }
      </div>
 
      { (title || caption || subtitle || description)
          && (
            <div className="block mt-2">
              { isPresent(caption)     && <p className="title is-6">{caption}</p> }
              { isPresent(title)       && <p className="title is-4">{title}</p> }
              { isPresent(subtitle)    && <p className="subtitle">{subtitle}</p> }
              { isPresent(description) && <Markdown source={description} />}
            </div>
          )
      }
    </div>
  );
};

export default ImageContent;