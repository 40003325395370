/* components/seo.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01e,30aug22,mno  named exports.
01d,20aug22,mno  use CDN for images.
01c,20jul22,mno  added missing key attr.
01b,19feb21,mno  encode meta tags.
01a,08oct20,mno  created.
*/

import React from "react";
import { Title, Link, Meta } from "react-head";
import cdnUrl from '../lib/cdn_url';

const Seo = (props) => {
  const { slug, title, description, meta  } = props;

  return (
    <>
      <Title>{title}</Title>
      <Meta name="description" content={description} />
      <Link rel="canonical" content={`https://tikkl.com${slug}`} />
      <Link rel="shortcut icon" href={cdnUrl('/favicon.ico')} />
      <Link rel="apple-touch-icon" href={cdnUrl('/images/tikkl/tikkl-logo-60x60.png')} />
      <Link rel="apple-touch-icon" sizes="76x76" href={cdnUrl('/images/tikkl/tikkl-logo-76x76.png')} />
      <Link rel="apple-touch-icon" sizes="120x120" href={cdnUrl('/images/tikkl/tikkl-logo-120x120.png')} />
      <Link rel="apple-touch-icon" sizes="152x152" href={cdnUrl('/images/tikkl/tikkl-logo-152x152.png')} />
      <Meta name="og:title" content={title} />
      <Meta name="og:description" content={description} />
      <Meta name="og:url" content={`https://tikkl.com${slug}`} />
      { 
        (meta || []).map((m) => (
          <Meta key={`meta-${m.key}`} name={m.key} content={m.value} />
        ))
      }
    </>
  );
};

export default Seo;