/* components/image_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,20jul22,mno  pass through borderRadius attr.
01a,08oct20,mno  created.
*/

import React from 'react';
import ImageContent from './image_content';
import SectionLayout from '../layouts/section_layout';

/**
 * Section Format: {
 *   sectionType: 'image',
 *   title: '',       // optional
 *   caption: '',     // optional
 *   description: '', // markdown (optional)
 *   align: left/right/center/justify
 *   spacing: small/medium/large,
 *   source: { url, alternativeText }
 *   size: thumbnail/icon_xx/small/medium/large/full
 *   shape: round/1by1/4by3/16by9...,
 * }
 * 
 * Props:
 * - section the section to render
  */
const ImageSection = (props) => {
  const { section, useLayout } = props;
  const { caption, title, subtitle, description, shape, align, size, source, borderRadius, pageLink } = section;
  const { url, alternativeText } = (source && source[0]) || {};
  
  return <SectionLayout useLayout={useLayout}>
    <ImageContent 
      caption={caption}
      title={title}
      subtitle={subtitle} 
      description={description}
      shape={shape}
      align={align}
      size={size}
      url={url||''} 
      alt={alternativeText}
      borderRadius={borderRadius}
      pageLink={pageLink}
    />
  </SectionLayout>
};

export default ImageSection;