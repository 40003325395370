/* lib/section_cx.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,22jul22,mno  emit has-dark-bg class.
01a,08oct20,mno  created.
*/

import classNames from 'classnames';
import tinyColor from 'tinycolor2';

const sectionCx = (section, cx) => (
  classNames(
    cx, section.className, {
      column: section.column,
      'has-text-centered': section.align === 'center',
      'has-text-justified': section.align === 'justify',
      'has-text-left': section.align === 'left',
      'has-text-right': section.align === 'right',
      'is-medium': section.spacing === 'medium',
      'is-large': section.spacing === 'large',
      'has-dark-bg': tinyColor(section.backgroundColor || '#fff').isDark(),
    }
  )
);

export default sectionCx;