/* components/video_content.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01b,30aug22,mno  named exports.
01a,08oct20,mno  created.
    20jul22,mno  cleanup.
*/
import React from 'react';
import VideoPlayer from 'react-player';
import classNames from 'classnames';

/*
const isShape = (shape) => {
  if (shape === 'circle' || shape === 'round') return 'is-1by1';
  return `is-${shape}`;
}

const isRounded = (shape) => {
  if (shape === 'circle' || shape === 'round') return 'is-rounded';
  return null;
}
*/

const VideoContent = (props) => {
  const { 
    size, url, className, align, isListItem 
  } = props;
  const cx = classNames(
    className, 
    'image-content', 
    `is-${size}`, 
    {
      'is-list-item': isListItem,
      'has-text-centered': align === 'center',
      'has-text-left': align === 'left',
      'has-text-right': align === 'right',
      'has-text-justified': align === 'justify'
    }
  );

  return (
    <div className={cx}>
      <div className={`image-block is-${size} has-shadow`}>
        <figure className={classNames('image is-16by9 has-rounded-corners')}>
          <VideoPlayer className="video-player" url={url} controls />
        </figure>
      </div>
    </div>
  );
};

export default VideoContent;