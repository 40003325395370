/* components/content_list_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,28jul22,mno  container specific attrs.
01a,08oct20,mno  created.
*/

import React from 'react';
import classNames from 'classnames';
import isPresent from '../lib/is_present';
import mapIndexed from '../lib/map_indexed';
import sectionCx from '../lib/section_cx';
import ImageContent from './image_content';
import TextContent from './text_content';
import SectionLayout from '../layouts/section_layout';

const LogoList = (props) => {
  const { items } = props;
  const listContainerCx = classNames('container content-list--image-container is-flex')

  if (!isPresent(items)) return null;

  return (
    <div className={listContainerCx} style={{ marginTop: '1em' }}>
      {
        mapIndexed(
          (item, index) => <ImageContent
            key={`image-list-item-${index}`}
            className="is-logo"
            url={item.source && item.source[0] && item.source[0].url}
            alt={item.source && item.source[0] && item.source[0].alternativeText}
            shape="logo"
            size="small"
            isListItem
          />,
          items
        ) 
      }
    </div>
  );
};

const ImageList = (props) => {
  const { 
    shape:   containerShape,
    size:    containerSize,
    align:   containerAlign,
    spacing: containerSpacing,
    items 
  } = props;
  const listContainerCx = classNames('container content-list--image-container is-flex')

  if (!isPresent(items)) return null;

  return (
    <div className={listContainerCx} style={{ marginTop: '1em' }}>
      {
        mapIndexed(
          (item, index) => <ImageContent
            key={`image-list-item-${index}`}
            containerShape={containerShape}
            containerSize={containerSize}
            containerAlign={containerAlign}
            containerSpacing={containerSpacing}
            shape={item.shape || containerShape || null}
            size={item.size || containerSize || null}
            align={item.align || containerAlign || null}
            spacing={item.spacing || containerSpacing || null}
            borderRadius={item.borderRadius || 0}
            caption={item.caption}
            title={item.title}
            subtitle={item.subtitle}
            description={item.description}
            url={item.source && item.source[0] && item.source[0].url}
            alt={item.source && item.source[0] && item.source[0].alternativeText}
            isListItem
          />,
          items
        ) 
      }
    </div>
  );
};

const TextList = (props) => {
  const { shape, size, items, align, spacing } = props;
  const listContainerCx = classNames('container content-list--text-container is-flex')

  if (!isPresent(items)) return null;

  return (
    <div className={listContainerCx}>
      {
        mapIndexed(
          (item, index) => <TextContent
            key={`image-list-item-${index}`}
            shape={shape}
            size={size}
            align={align}
            spacing={spacing}
            {...item}
          />,
          items
        ) 
      }
    </div>
  );
};

const ContentList = (props) => {
  const { section, useLayout } = props;
  const { 
    itemSize, itemShape, items, backgroundColor, image, itemAlign,
    title, subtitle, description, sectionType 
  } = section;

  return (
    <SectionLayout useLayout={useLayout}>
      <section className={sectionCx(section, 'section section--content-list')} style={{ backgroundColor }}>
        <div className="container has-text-centered">
          {
            (isPresent(title) || isPresent(subtitle) || isPresent(description)) 
            && <TextContent className="container section-header" {...section} />
          }
          {isPresent(image) && <ImageContent {...image} />}
          {sectionType === 'image-list' && <ImageList shape={itemShape} size={itemSize} align={itemAlign} items={items} />}
          {sectionType === 'text-list' && <TextList shape={itemShape} size={itemSize} align={itemAlign} items={items} />}
          {sectionType === 'logo-list' && <LogoList items={items} />}
        </div>
      </section>
    </SectionLayout>
  );
};

export default ContentList;
