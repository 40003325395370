/* templates/page.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01d,30aug22,mno  named exports.
01c,20jul22,mno  updated graphql attributes; use tikklUrl for API access.
01b,04oct21,mno  added form attributes to section.
01a,08oct20,mno  created.
*/

import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import axios from 'axios';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import HeadSection from '../components/head_section';
import PageContentSections from '../components/page_content_sections';
import Seo from '../components/seo';
import tikklUrl from '../lib/tikkl_url';
import "./styles.scss";

const buildSubSections = (sections, idx) => {
  const section = sections[idx];
  let subSections = null;
  let count = 0;

  if (section && section.count && section.count > 0) {
    subSections = [];
    for (let i=0; i < section.count; i++){
      count++;
      const { subSections: ss, count: c } = buildSubSections(sections, idx + count);
      sections[idx + count]._subSections = ss;
      subSections.push(sections[idx + count]);
      count += c;
    }
  } 

  return { subSections, count };
} 

const buildSectionTree = (sections) => {
  const sectionTree = [];
  for (let idx=0; idx < sections.length; idx++){
    sectionTree.push(sections[idx]);
    const { subSections, count } = buildSubSections(sections, idx);
    if (subSections) {
      sections[idx]._subSections = subSections;
      idx += count;
    }
  }
  return sectionTree;
}

export default function Page({ data }) {
  const sections = buildSectionTree(data.strapiPage.sections);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    axios.get(tikklUrl('/api/me?summary=true'))
      .then((res) => {
        // console.log('me: ', res);
        const { data: me } = res;
        /*
        // debug
        const { data: me } = { data: {
          name:        { firstName: 'Tikkl', lastName: 'Support' },
          status:      "active",
          user_handle: "support",
        }};
        */

        const fullName = (me.status === 'active') ? [me.name.firstName, me.name.lastName].join(' ') : null;
        if (fullName !== userName){
          setUserName(fullName);
        }
      })
      .catch();
  }, [userName]);
  
  return (
    <>
      <Seo 
        slug={data.strapiPage.slug}
        title={data.strapiPage.pageTitle} 
        description={data.strapiPage.pageDescription}
        meta={data.strapiPage.meta}
      />
      <Navbar userName={userName}/>
      <HeadSection section={data.strapiPage.head} />
      <PageContentSections isPrimarySection sections={sections} useLayout className="section" slug={data.strapiPage.slug} />
      <Footer />
    </>
  );
}

export const query = graphql`
  query PageTemplate($id: String!) {
    strapiPage(id: {eq: $id}) {
      pageName
      slug
      pageTitle
      pageDescription
      meta {
        key
        value
      }

      head {
        sectionType
        title
        subtitle
        align
        spacing
        cta {
          text
          url
          color
          isOutline
        }
        description
        descriptionAlign        
      }

      sections {
        sectionType
        title
        titleHighlight
        subtitle
        caption
        description
        source {
          url
          alternativeText
        }
        align
        size
        shape
        bulletStyle
        borderRadius
        backgroundColor
        url
        pageLink

        postTo
        formName
        successMessage

        price {
          amount
          term
          description
        }
        
        ctaButton {
          text
          url
          color
          isOutline
        }

        columnWidth
        borderWidth

        rowSeparator
        count
        itemSize
        itemShape
        itemAlign

        items {
          title
          subtitle
          caption
          description
          bulletStyle
          size
          shape
          source {
            url
            alternativeText
          }
          name
          fieldType
          selectOptions
          mandatory
          max
          hidePlaceholder
          borderRadius
        }
      }
    }
  }
`