/* components/multi_row_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,20jul22,mno  removed dead attr: align.
01a,08oct20,mno  created.
*/

import React from 'react';
import classNames from 'classnames';
import isPresent from '../lib/is_present';
import sectionCx from '../lib/section_cx';
import PageContentSections from './page_content_sections';
import mapIndexed from '../lib/map_indexed';
import TextContent from './text_content';
import SectionLayout from '../layouts/section_layout';

/**
 * Format: {
 *  sectionType: 'multi-row',
 *  align: left/right/center/justify,
 *  spacing: medium/large,
 *  title: String,
 *  subtitle: String,
 *  description: String|Markdown,
 *  rows: [
 *    { sectionType: ...}, 
 *    { sectionType: ...}, 
 *    ...
 *    { sectionType: ...},
 *  ]
 * }
 */
const MultiRowSection = (props) => {
  const { section, rows, useLayout } = props;
  const { title, subtitle, description, rowSeparator, backgroundColor } = section;

  return (
    <SectionLayout useLayout={useLayout}>
      <section className={sectionCx(section, 'section')} style={{ backgroundColor }}>
        <div className="container">
          {
            (isPresent(title) || isPresent(subtitle) || isPresent(description)) 
            && <TextContent className="container section-header" {...section} />
          }
          <div className={classNames('rows', { 
            [`has-row-separator-${rowSeparator}`]: rowSeparator,
          })}>
            {
              mapIndexed((row, idx) => (
                  <div key={`row-${idx}`} className="row">
                    <PageContentSections sections={[row]} isSubsection/>
                  </div>
                ),
                rows
              )
            }
          </div>
        </div>
      </section>
    </SectionLayout>
  );
};

export default MultiRowSection;