/* components/text_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,22aug24,mno  custom Markdown component.
01b,30aug22,mno  named exports.
01a,08oct20,mno created.
*/

import React from 'react';
import classNames from 'classnames';
import isPresent from '../lib/is_present';
import descriptionCx from '../lib/description_cx';
import sectionCx from '../lib/section_cx';
import Markdown from './markdown'; 
import TitleText from './title_text';

/**
 * Data Format:
 *  {
 *   sectionType: 'text',
 *   title: 'Captivating event landing pages, in minutes',
 *   subtitle: '',
 *   description: `
 *     - Easy template driven process
 *     - Responsive design for web and mobile
 *     - Branded, ad-free pages
 *     - Organizer microsite featuring events lineup
 *     - Attendee wall
 *     - Make your event public or private
 *   `,
 *   bulletStyle: 'checkmark',
 *   align: left/right/center/justify,
 *   spacing: medium/large,
 *  }
 * 
 * Props:
 * - section the section to render.
 * - isColumn render section in a column.
 */
const TextContent = (props) => {
  const { title, subtitle, description, isSubsection, shape } = props;
  const titleCx = classNames('title is-spaced', {
    'is-3': isSubsection
  });

  return(
    <div className={sectionCx(props, 'text-content')}>
      <div className={classNames('container', { box: shape === 'box' })}>
        { isPresent(title) && <TitleText className={titleCx} title={title}/> }
        { isPresent(subtitle) && <TitleText className="subtitle" title={subtitle} /> }
        { 
          isPresent(description) 
            && <Markdown className={descriptionCx(props, 'description content')} source={description} />
        }
      </div>
    </div>
  );
};

export default TextContent;