/* components/page_content_sections.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,20jul22,mno  render ContentListSections as primary; BioSection.
01a,08oct20,mno  created.
*/

import React from 'react';
import ContentListSection from './content_list';
import MultiColumnSection from './multi_column_section';
import MultiRowSection from './multi_row_section';
import TextSection from './text_section';
import ImageSection from './image_section';
import BioSection from './bio_section';
import VideoSection from './video_section';
import PricingPlans from './pricing_plans_section';
import FormSection from './form_section';
import mapIndexed from '../lib/map_indexed';

const PageContentSections = (props) => {
  const { sections, className, useLayout, isSubsection, slug } = props;

  return mapIndexed(
    (section, idx) => {
      const sectionProps = { section, className, useLayout, isSubsection };

      switch (section.sectionType){
        case 'content-list':
        case 'text-list':
        case 'image-list':
        case 'logo-list':
          return (
            <ContentListSection isPrimarySection key={`page-section-${idx}`} {...sectionProps} />
          );
        case 'multi-column':
          return (
            <MultiColumnSection 
              key={`multi-column-section-${idx}`} 
              columns={section._subSections} 
              {...sectionProps}
            />
          );
        case 'multi-row':
          return (
            <MultiRowSection 
              key={`multi-row-section-${idx}`} 
              rows={section._subSections} 
              {...sectionProps}
            />
          );
        case 'text':
          return (
            <TextSection key={`text-content-${idx}`} {...sectionProps} />
          );
        case 'image':
          return (
            <ImageSection key={`image-content-${idx}`} {...sectionProps} />
          );
        case 'bio':
          return (
            <BioSection key={`bio-content-${idx}`} {...sectionProps} />
          );
        case 'video':
          return (
            <VideoSection key={`image-content-${idx}`} {...sectionProps} />
          );          
        case 'pricing-plans':
          return (
            <PricingPlans 
              key={`pricing-plan-${idx}`} 
              plans={section._subSections}
              slug={slug}
              {...sectionProps}
            />
          );
        case 'form':
          return (
            <FormSection 
              key={`form-${idx}`} 
              fieldGroups={section._subSections}
              {...sectionProps}
            />
          );
        default:
          return null;
      }
    },
    sections
  );
};

export default PageContentSections;