/* layout/hero_layout.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,26apr24,mno  replaced performer with conferenceAudience image.
                 updated CTA box images.
01b,30aug22,mno  named exports.
01a,08oct20,mno  created
                 cleanup.
*/

import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 512) {
      ...GatsbyImageSharpFluid_noBase64
    }
  }
}
`;

const HeroLayout = (props)  => {
  const images = useStaticQuery(graphql`
    query {
      performer: file(relativePath: {eq: "performer.png"}) { ...fluidImage }
      coach: file(relativePath: {eq: "coach.png"}) { ...fluidImage }
      audience: file(relativePath: {eq: "dancer.png"}) { ...fluidImage }
      conferenceAudience: file(relativePath: {eq: "conference_audience_v2.png"}) { ...fluidImage }
      bloomBlue: file(relativePath: {eq: "bloom_blue.png"}) { ...fluidImage }
      bloomOrange: file(relativePath: {eq: "bloom_orange.png"}) { ...fluidImage }
      bloomPurple: file(relativePath: {eq: "bloom_purple.png"}) { ...fluidImage }
      bloomGreen: file(relativePath: {eq: "bloom_green.png"}) { ...fluidImage }
    }
  `);
  /*

  */

  return (
    <div className="layout layout--hero">
      <Img className="layout--image bloom bloom-green" fixed={images.bloomGreen.childImageSharp.fluid} />
      <Img className="layout--image bloom bloom-blue" fixed={images.bloomBlue.childImageSharp.fluid} />
      <Img className="layout--image bloom bloom-orange" fixed={images.bloomOrange.childImageSharp.fluid} />
      <Img className="layout--image bloom bloom-purple" fixed={images.bloomPurple.childImageSharp.fluid} />
      <Img className="layout--image conference-audience" fixed={images.conferenceAudience.childImageSharp.fluid} />
      <Img className="layout--image coach" fixed={images.coach.childImageSharp.fluid} />
      <Img className="layout--image audience" fixed={images.audience.childImageSharp.fluid} />
      { /*
      */}
      {props.children}
    </div>
  );
}

export default HeroLayout;