/* components/markdown.js */

/* Copyright 2024 - present Tikkl, Inc. */

/*
modification history
--------------------
01a,22aug24,mno  created.
*/
import React from 'react';

var md = require('markdown-it')();
var markdownItAttrs = require('markdown-it-attrs');

md.use(markdownItAttrs, {
  // optional, these are default options
  leftDelimiter: '{',
  rightDelimiter: '}',
  allowedAttributes: []  // empty array = all attributes are allowed
});

const MarkdownRenderer = ({source, ...props}) => {
  const content = md.render(source);
  return <div {...props} dangerouslySetInnerHTML={{__html: content}} />
}

export default MarkdownRenderer;
