/* lib/description_cx.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01b,30aug22,mno  named exports.
01a,08oct20,mno  created.
*/

import classNames from 'classnames';

const descriptionCx = (section, cx) => (
  classNames(
    cx,
    'text-description', {
      [`has-list-style-${section.bulletStyle}`]: section.bulletStyle,
      'has-list-style-disc': !section.bulletStyle,
      'has-text-centered': section.descriptionAlign === 'center',
      'has-text-left': section.descriptionAlign === 'left',
      'has-text-right': section.descriptionAlign === 'right',
      'has-text-justified': section.descriptionAlign === 'justify',
    }
  )
);

export default descriptionCx;