/* components/head_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01e,22aug24,mno  render with TitleText component; custom Markdown component.
01d,18aug23,mno  added `content` class to markdown-description div
01c,30aug22,mno  named exports.
01b,20jul22,mno  CTA button isRounded; process <br> in title.
01a,08oct20,mno  created.
*/

import React from 'react';
import Markdown from './markdown';
import ButtonLink from './button_link';
import isPresent from '../lib/is_present';
import sectionCx from '../lib/section_cx';
import descriptionCx from '../lib/description_cx';
import HeroLayout from '../layouts/hero_layout';
import SectionLayout from '../layouts/section_layout';
import TitleText from './title_text';

/*
Head Section
------------

Renders sectionTypes: 'hero' and 'page-title'
Options:
- title (required)
- subtitle
- padding {normal*, medium, large}
- align {left*, right, center, justified}
- cta {only for hero sections}
  - text
  - url
*/

const Hero = (props) => {
  const { section } = props;
  const { title, subtitle, description, cta, backgroundColor } = section;
  const ctaColor = cta?.color || 'primary';

  return (
    <HeroLayout>
      <section 
        className={sectionCx(section, 'hero section--hero intro')} 
        style={{ backgroundColor }}
      >
        <div className="hero-body">
          <div className="container section-header">
            <div className="mb-5">
              <TitleText className="title is-1 mb-0" title={title} />
            </div>
            {
              isPresent(subtitle) 
                && <TitleText className="subtitle has-text-weight-semibold" title={subtitle} />
            }
            {
              isPresent(description) 
                && <Markdown className={descriptionCx(section, 'description')} source={description} />
            }
            {
              isPresent(cta)
                && <div className="cta">
                    <ButtonLink 
                      buttonText={cta.text}
                      to={cta.url} 
                      isRounded
                      isPrimary={ctaColor === 'primary'}
                      isSecondary={ctaColor === 'secondary'}
                      isOutlined={cta.isOutline}
                      isLarge
                    />
                </div>
            }
          </div>
        </div>
      </section>
    </HeroLayout>
  );
};

const PageTitle = (props) => {
  const { section } = props;
  const { title, subtitle, description } = section;

  return (
    <SectionLayout useLayout maxBlooms={4}>
      <div className={sectionCx(section, 'section section--page-title')}>
        <div className="container section-header">
          <TitleText className="title is-1 is-spaced" title={title} />
          {
            isPresent(subtitle) 
              && <TitleText className="subtitle" title={subtitle} />
          }
          {
            isPresent(description)
              && <Markdown className={descriptionCx(section, 'description content')} source={description} />
          }
        </div>
      </div>
    </SectionLayout>
  );
}

const HeadSection = (props) => {
  const { section } = props;

  switch (section.sectionType) {
    case 'hero':       return <Hero section={section} />;
    case 'page-title': return <PageTitle section={section} />;
    default:           return null;
  }
};

export default HeadSection;