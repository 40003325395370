/* components/text_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01b,30aug22,mno  named exports.
01a,08oct20,mno created.
*/

import React from 'react';
import TextContent from './text_content';
import SectionLayout from '../layouts/section_layout';

/**
 * Section Format: {
 *   sectionType: 'text',
 *   title: '',       // optional
 *   caption: '',     // optional
 *   description: '', // markdown (optional)
 *   centeredText: Boolean
 *   spacing: medium/large,
 *   source: String|URL
 *   alt: String,
 *   size: 'medium',
 *   shape: '4by3',
 * }
 * 
 * Props:
 * - section the section to render
 * - isColumn 
 */
const TextSection = (props) => {
  const { section, useLayout, isSubsection } = props;
  const { backgroundColor } = section;

  return (
    <SectionLayout useLayout={useLayout}>
      <section className="section text-section" style={{ backgroundColor }}>
        <TextContent {...section} isSubsection={isSubsection} />
      </section>
    </SectionLayout>
  );
};

export default TextSection;