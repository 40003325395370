/* components/title_text.js */

/* Copyright 2024 - present Tikkl, Inc. */

/*
modification history
--------------------
01a,22aug24,mno  created.
*/

import React from 'react';
import mapIndexed from '../lib/map_indexed';
import classNames from 'classnames';

/**
 * splits the title by <br> and renders each item with specified classNames.
 * @param {String} title - the title to render.
 * @param {String} className - the class names to apply
 */
const TitleText = ({title, className}) => (
  <>
    {
      mapIndexed(
        (t, i, {isFirst, isLast}) => (
          <p className={classNames(className, { 'mb-0': !isLast })} key={`title-${i}`}>{t}</p>
        ),
        title.split('<br>')
      )
    }
  </>
);

export default TitleText;