/* components/multi_column_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,28jul22,mno  distinguish between container/item backgroundColor.
01a,08oct20,mno  created.
*/

import React from 'react';
import classNames from 'classnames';
import isPresent from '../lib/is_present';
import sectionCx from '../lib/section_cx';
import TextContent from './text_content';
import PageContentSections from './page_content_sections';
import mapIndexed from '../lib/map_indexed';
import SectionLayout from '../layouts/section_layout';

/**
 * Format: {
 *  sectionType: 'multi-column',
 *  align: left/right/center/justify,
 *  spacing: medium/large,
 *  title: String,
 *  subtitle: String,
 *  description: String|Markdown,
 *  columns: [
 *    { sectionType: ...}, 
 *    { sectionType: ...}, 
 *    ...
 *    { sectionType: ...},
 *  ]
 * }
 */
const MultiColumnSection = (props) => {
  const { section, columns, useLayout } = props;
  const { title, subtitle, description, align, backgroundColor } = section;

  return (
    <SectionLayout useLayout={useLayout}>
      <section className={sectionCx(section, 'section')} style={{ backgroundColor, textAlign: align }}>
        <div className="container">
          {
            (isPresent(title) || isPresent(subtitle) || isPresent(description)) 
            && <TextContent className="container section-header" {...section} />
          }

          <div className="columns">
            {
              mapIndexed((col, idx) => {
                  const { columnWidth, borderWidth, backgroundColor: bg } = col;
                  const cx = classNames(
                    'column', 
                    isPresent(columnWidth) ? `is-${columnWidth}` : '', 
                    isPresent(borderWidth) ? `has-border-${borderWidth}` : '',
                  );
                  let style={ backgroundColor: bg };
                  return (
                    <div key={`col-${idx}`} className={cx} style={style}>
                      <PageContentSections sections={[col]} isSubsection />
                    </div>
                  );
                },
                columns
              )
            }
          </div>
        </div>
      </section>
    </SectionLayout>
  );
}

export default MultiColumnSection;