/* components/pricing_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01e,22aug24,mno  custom Markdown component.
01d,27mar24,mno  renamed global -> USA.
01c,30aug22,mno  named exports.
01b,20jul22,mno  updated HTML pricing-level-title; tab navigation.
01a,08oct20,mno  created.
*/

import React from 'react';
import { toKebabCase as kebabCase } from 'ramda-extension';
import classNames from 'classnames';
import ButtonLink from './button_link';
import isPresent from '../lib/is_present';
import MapIndexed from '../lib/map_indexed';
import SectionLayout from '../layouts/section_layout';
import FlagIndia from '../../static/flag_in.svg';
import FlagUsa from '../../static/flag_us.svg';
import Markdown from './markdown'; 

const PricingPlan = (props) => {
  const { title, titleHighlight, subtitle, description, price, align, ctaButton } = props.plan;

  return (
    <div
      className={classNames('column is-flex pricing-level is-flex-direction-column is-justify-content-space-between', {
        'has-text-centered': align === 'center'
      })}
    >
      <div style={{ marginBottom: '2em' }}>
        <div className="pricing-level-title">
          <div
            style={{ backgroundColor: titleHighlight}}
            className={`title is-4 is-${kebabCase(title)} is-spaced`}
          >
            {title}
          </div>
          <Markdown source={subtitle} className="subtitle is-6 is-text-normal" />
        </div>

        <div className="pricing-level-price">
          <div className="mb-1">
            <span className="title is-4 has-text-weight-bold pricing--amount">{price.amount}</span>
            {isPresent(price.term) && <span className="title is-7 pricing--term" style={{ width: '3em', display: 'inline-block'}}> {price.term} </span>}
          </div>
          {
            isPresent(price.description )
              && <p className="pricing--description title is-6 has-text-weight-normal">{price.description}</p>
          }
        </div>
        {
          isPresent(description) && <div className="pricing-level-features has-text-left">
            <p className="title is-6 is-uppercase">Features:</p>
            <Markdown source={description} className="features-list content is-normal" />
          </div>
        }
      </div>
      <div className='cta-button' style={{ flexAlign: 'bottom' }}>
        <ButtonLink to={ctaButton.url} buttonText={ctaButton.text} isPrimary isOutlined isMedium isRounded />
      </div>
    </div>
  );
};

const PricingPlanSections = (props) => {
  const { /*section,*/ plans, backgroundColor, useLayout, slug } = props;
  const pageName = slug.split('/');
  const wwCx = classNames('is-tab', { 'is-active': pageName[1] === 'pricing' });
  const inCx = classNames('is-tab', { 'is-active': pageName[1] === 'pricing-india' });

  return (
    <SectionLayout useLayout={useLayout}>
      <section className="section section--pricing-levels" style={{ backgroundColor }}>
        <div className="tabs container">
          <ul className="is-justify-content-flex-end" style={{ borderBottom: 'none' }}>
            <li className="title is-4 mb-4 mr-5 has-text-weight-semibold">Pricing</li>
            <li><a className={wwCx} href="/pricing"><FlagUsa /> USA </a></li>
            <li><a className={inCx} href="/pricing-india"><FlagIndia /> India </a></li>
          </ul>
        </div>
        <div className="container">
          <div className="columns">
            { MapIndexed((plan, idx) => <PricingPlan key={`plan-${idx}`} plan={plan} />, plans) }
          </div>
        </div>
      </section>
    </SectionLayout>
  );
};

export default PricingPlanSections;
