/* components/bio_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,22aug24,mno  custom Markdown component.
01b,30aug22,mno  named exports.
01a,28jul22,mno  created.
*/

import React from 'react';
import classNames from 'classnames';
import { includes, any } from 'ramda';
import SectionLayout from '../layouts/section_layout';
import Markdown from './markdown'; 
import CdnImg from './cdn_img';

const SHAPES = [
  /logo/,
  /1by[123]/, 
  /2by[13]/, 
  /3by[1245]/, 
  /4by[35]/, 
  /16by9/, 
  /9by16/
];

const isShape = (shape) => {
  if (includes(shape, ['circle', 'round'])) return 'is-1by1';
  if (any(r => r.test(shape), SHAPES)) return `is-${shape}`;
  return '';
}

/**
 * Section Format: {
 *   sectionType: 'bio',
 *   title: '',       // optional
 *   caption: '',     // optional
 *   description: '', // markdown (optional)
 *   align: left/right/center/justify
 *   spacing: small/medium/large,
 *   source: { url, alternativeText }
 *   size: thumbnail/icon_xx/small/medium/large/full // ignored
 *   shape: round/1by1/4by3/16by9..., // ignored
 * }
 * 
 * Props:
 * - section the section to render
 */
const BioSection = (props) => {
  const { section, useLayout } = props;
  const { caption, title, subtitle, description, shape, align, /*size,*/ source, /*borderRadius*/ } = section;
  const { url, alternativeText: alt, size: imgSize } = (source && source[0]) || {};

  const cx = classNames(
    `image-content`, 
    isShape(shape),
    {
      'has-text-centered':  align === 'center',
      'has-text-left':      align === 'left',
      'has-text-right':     align === 'right',
      'has-text-justified': align === 'justify'
    }
  );
  
  return <SectionLayout useLayout={useLayout}>
    <div className={cx}>
      <div className={`image-block is-avatar`}>
        <figure 
          className={'image is-square'}
        >
          <CdnImg size={imgSize || "small"} src={url} alt={alt} className={`is-rounded`}/>
        </figure>
      </div>

      { (title || caption || subtitle || description) && <div className="block">
          { caption &&     <p className="title is-6">{caption}</p> }
          { title &&       <p className="title is-4">{title}</p> }
          { subtitle &&    <p className="subtitle">{subtitle}</p> }
          { description && <Markdown source={description} />}
        </div>
      }
    </div>
  </SectionLayout>
};

export default BioSection;