/* lib/map_indexed.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,22aug24,mno  call mapper with options: isFirst and isLast.
01b,30aug22,mno  named exports.
01a,08oct20,mno  created.
*/

const mapIndexed = (mapper, list) => {
  let idx = 0;
  let items = [];
  list.forEach((i) => items.push(mapper(i, idx++, {isFirst: idx === 1, isLast: idx === list.length})));
  return items;
};

export default mapIndexed;