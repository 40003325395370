/* components/video_section.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01b,30aug22,mno  named exports.
01a,08oct20,mno created.
*/

import React from 'react';
import VideoContent from './video_content';
import SectionLayout from '../layouts/section_layout';

/**
 * Section Format: {
 *   sectionType: 'image',
 *   title: '',       // optional
 *   caption: '',     // optional
 *   description: '', // markdown (optional)
 *   align: left/right/center/justify
 *   spacing: small/medium/large,
 *   source: { url, alternativeText }
 *   size: thumbnail/icon_xx/small/medium/large/full
 *   shape: round/1by1/4by3/16by9...,
 * }
 * 
 * Props:
 * - section the section to render
  */
const VideoSection = (props) => {
  const { section, useLayout } = props;
  const { caption, title, subtitle, description, align, size, url } = section;
  // console.log('video-section props=', props);
    
  return <SectionLayout useLayout={useLayout}>
    <VideoContent 
      caption={caption}
      title={title}
      subtitle={subtitle} 
      description={description}
      align={align}
      size={size || 'large'}
      url={url} 
    />
  </SectionLayout>
};

export default VideoSection;