/* components/button.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01d,22aug24,mno  support isSecondary.
01c,30aug22,mno  named exports.
01b,20jul22,mno prop: isRound.
01a,09oct20,mno created.
*/

import React from "react";
import { Link } from 'gatsby';
import classnames from 'classnames';
import isPresent from '../lib/is_present';

const ButtonLink = (props) => {
  const { to, href, buttonText, isPrimary, isSecondary, isOutlined, isLarge, isMedium, isSmall, isNormal, isRounded } = props;
  const cx = classnames('button', {
    'is-primary':   isPrimary,
    'is-secondary': isSecondary,
    'is-outlined':  isOutlined,
    'is-large':     isLarge,
    'is-medium':    isMedium,
    'is-normal':    isNormal, // default
    'is-small':     isSmall,
    'is-rounded':   isRounded,
  });

  if (isPresent(to)){
    return (
      <Link to={to} className={cx}>
        <span className="button-text">{buttonText}</span>
      </Link>    
    );
  }

  return (
    <a href={href} className={cx}>
      <span className="button-text">{buttonText}</span>
    </a>  
  );
};

export default ButtonLink;