/* components/cdn_img.js - higher order CDN-aware img component */

/* Copyright 2022 - present Tikkl, Inc. */

/*
modification history
--------------------
01b,30aug22,mno  named exports.
01a,17aug22,mno  created.
*/

import React from 'react';
import cdnUrl from '../lib/cdn_url';

const CdnImg = (props) => {
  const { src, size, alt, ...rest } = props;
  return <img src={cdnUrl(src, size || 'medium')} alt={alt || ''} {...rest} />;
};

export default CdnImg;