/* layout/section_layout.js */

/* Copyright 2020 - present Tikkl, Inc. */

/*
modification history
--------------------
01c,30aug22,mno  named exports.
01b,20jul22,mno useLayout param > layout--primary class.
01a,08oct20,mno created.
*/

import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import { includes, reduce } from 'ramda';
import classNames from 'classnames';
import mapIndexed from '../lib/map_indexed';

const BLOOM_COLORS = ['blue', 'purple', 'orange', 'green'];
const BLOOM_TYPES = [1, 2, 3, 4, 5, 6, 7];
const rand = (max) => Math.floor(Math.random() * max);
const randomItem = (list) => list[rand(list.length)];

const randomBlooms = (max, min=0) => {
  const count = rand(max - min) + min + 1;
  let bloomTypes = [];

  while (bloomTypes.length < count){
    const t = randomItem(BLOOM_TYPES);
    if (!includes(t, bloomTypes)){
      bloomTypes.push(t);
    }
  }

  return reduce(
    (acc, type) => { acc.push({color: randomItem(BLOOM_COLORS), type }); return acc; }, 
    [], 
    bloomTypes
  );
};

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 512) {
      ...GatsbyImageSharpFluid_noBase64
    }
  }
}
`;

const SectionLayout = (props)  => {
  const images = useStaticQuery(graphql`
    query {
      bloomBlue:   file(relativePath: {eq: "bloom_blue.png"}) { ...fluidImage }
      bloomOrange: file(relativePath: {eq: "bloom_orange.png"}) { ...fluidImage }
      bloomPurple: file(relativePath: {eq: "bloom_purple.png"}) { ...fluidImage }
      bloomGreen:  file(relativePath: {eq: "bloom_green.png"}) { ...fluidImage }                  
    }
  `);
  
  const imageMap = {
    blue:   images.bloomBlue.childImageSharp.fluid,
    orange: images.bloomOrange.childImageSharp.fluid,
    purple: images.bloomPurple.childImageSharp.fluid,
    green:  images.bloomGreen.childImageSharp.fluid,
  };

  const cx = classNames({
    'layout':          true,
    'layout--section': true,
    'layout--primary': props.useLayout,
  });

  const blooms = randomBlooms(props.maxBlooms || 1); 
  return (
    <div className={cx}>
      { 
        props.useLayout 
          && <div>
            {
              mapIndexed(
                ({color, type}, idx) => <Img
                  key={`img-${idx}`}
                  className={`layout--image bloom bloom-${color} bloom-${type}`}
                  fixed={imageMap[color]} 
                />,
                blooms
              )
            }
        </div>
      }
      {props.children}
    </div>
  );
};

export default SectionLayout;