/* components/tikkl_url.js */

/* Copyright 2022 - present Tikkl, Inc. */

/*
modification history
--------------------
01b,30aug22,mno  named exports.
01a,02aug22,mno  created.
*/

const tikklUrl = (path) => (`${process.env.GATSBY_BASE_URL || ''}${path}`);
export default tikklUrl;